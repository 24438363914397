import React, { useState, ReactElement } from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import {
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
} from "react-icons/all"

import { Title } from "src/componentsV2/sections/Text"

interface FAQDataItem {
  title: string
  content: ReactElement
  isCollapsed?: boolean
}

const FAQData: FAQDataItem[] = [
  {
    title: "Can you integrate with Single Sign On (SSO) or HRIS providers?",
    content: <>
      Yes!  We have ready-to-go SSO integrations with GSuite, Microsoft Azure, Active Directory, Okta, OneLogin and Idaptive.
      Also, we have ready-to-go integrations with HRIS providers such as BambooHR, Paylocity, PeopleHR, BreatheHR,
      Kronos, UltiPro. On top of this, we’re able to provide SFTP integrations with other providers.
    </>,
  },
  {
    title:
      "Our content is from the early 2000s!  Do you offer copywriting services?",
    content: <>
      <p>Yes! Let our copywriters do the heavy lifting when it comes to your content.
        It's no secret that re-writing an employee handbook can be a long, stressful task...
        Which is why we have such a high turnover rate for this position.</p>
      <p>
        Just kidding! Really though, if you enjoyed the little bit of personality in this description
        then you'll love our writers. They specialize in creating a modern tone in line with your brand,
        and one which speaks with your employees rather than at them, all while condensing the content
        and making it a more enjoyable read.</p>
    </>,
  },
  {
    title: "I'm not a designer :( Can you help with our design?",
    content: <>
      Absolutely! Just share your brand guidelines and other marketing materials that we should
      use, and AirMason’s awesome creative team will create beautiful custom templates around this.
      We work closely with you to represent your brand throughout your documentation and make the
      entire experience enjoyable for employees to interact with. This can include custom fonts, brand colors,
      logo and image resizing, as well as the inclusion of videos, GIFs, tables and links to external documents —
      so all content is accessible in one engaging, moreover organized place.
    </>,
  },
  {
    title: "Is AirMason just for employee handbooks?",
    content: <>
      No! Although employee handbooks were our first love… We’ve grown to love other documents too.
      Our platform can support employee benefit books, SOP’s, training manuals, health and safety manuals,
      Country or State specific policies, even newsletters, and many many more. A lot of our clients use AirMason
      to build stunning culture books too, for use in talent attraction and onboarding.
      The possibilities are, quite literally, endless!
    </>,
  },
  {
    title: "How do employees view handbooks?",
    content: <>
      Two ways! First - we create an employee login portal
      (ex.<a href="https://books.airmason.com/airmason" target="_blank">https://books.airmason.com/airmason</a>)
      which can be accessed anytime, anywhere, from any device, for your employees to view their
      digital library of documents. Second - in real-time. Send document edits and updates in
      real-time with our internal mailing system. The email will come from a custom internal
      email of your choosing and you can customize any messaging coming from the platform.
      They will click a link that directs them right to the document you just worked on; this way,
      you can track when they have received, opened and signed your documents.
    </>,
  },
  {
    title: "Can employees view on their phones?",
    content: <>
      Yes! Our handbooks are mobile and tablet responsive.
    </>,
  },
  {
    title: "How many documents can I have in my account?",
    content: <>
      You can host unlimited documents with any AirMason subscription.
    </>,
  },
  {
    title: "Do you provide help with initial set up or customer support?",
    content: <>
      Yes! We have a world-class customer success team on-hand to walk you through the process
      from the get-go. We’re here every step of the way, to support you in making sure your
      account is set up properly and settings are exactly how you want them through to ongoing support post-launch.
    </>,
  },
  {
    title: "How do the edits work?",
    content: <>
      Each document/handbook has its own editor that allows us to make editing very simple.
      Anytime you want to change up some content or branding, update a policy or add an image,
      just go into your editor and use the tools to do so. You can even share those edits there and
      then with employees or groups of your choosing from directly within.
    </>,
  },
  {
    title: "How long has AirMason been around?",
    content: <>
      <p>Since late 2016. Each member of our team is at the top of their craft — with over 50+ years of
        combined experience across development, design, content creation and customer service,
        we are experts in turning your former handbook(s) into a digitally branded experience that will
        benefit both your internal process and your employees' HR experience.</p>

      <p>Since launching AirMason, we’ve experienced our own growing pains, which gives us a first-hand appreciation
        towards the importance of a great onboarding experience and employee handbook. To discover what the
        HR world needed, we gathered feedback from our clients, HR leaders,and their teams all over the planet
        to build our platform to what you see today — and it's still growing!</p>
    </>,
  },
  {
    title: "Do you normally work with companies like us?",
    content: <>
      We work with companies from all over the world! No matter how big or small your organization is,
      we provide a tailored solution that can benefit your HR documentation process.
    </>,
  },
]

interface FAQContentProps {
  isCollapsed: boolean
}

const FAQTitle = styled.div`
  ${tw`text-blue-2`}
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;

  .icon {
    margin-left: 15px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    font-size: 25px;
    padding: 20px 0px 25px;

    .icon {
      margin-left: 30px;
      font-size: 23px;
    }
  }
`

const FAQContent = styled.div<FAQContentProps>`
  ${tw`text-gray-1`}
  overflow: hidden;
  letter-spacing: 0;
  margin-bottom: 25px;
  font-size: 18px;
  color: #676b87;
  line-height: 27px;
  font-weight: 400;

  p {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  ${props =>
    props.isCollapsed
      ? `
        max-height: 0;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), margin 0.5s cubic-bezier(0, 1, 0, 1);
        margin-bottom: 0;
      `
      : `
        max-height: 1000px;
        transition: max-height 1.5s ease-in-out;
      `};

  @media (min-width: 768px) {
    font-size: 18px;

    p {
      margin-bottom: 25px;
    }
  }
`

const FAQItem = styled(props => {
  const { item } = props
  const [isCollapsed, setIsCollapsed] = useState(true)

  function toggleCollapsed(): void {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className={`faq-item ${props.className}`}>
      <FAQTitle className="faq-title cursor-pointer" onClick={toggleCollapsed}>
        <div className="title">{item.title}</div>
        <div className="icon">
          {isCollapsed ? (
            <IoIosArrowDropdownCircle />
          ) : (
            <IoIosArrowDropupCircle />
          )}
        </div>
      </FAQTitle>
      <FAQContent isCollapsed={isCollapsed} className="faq-content">
        {item.content}
      </FAQContent>
    </div>
  )
})`
  border-bottom: 1px solid rgb(151 151 151 / 36%);
`

const SectionTitle = styled(Title)`
  ${tw`max-w-xs mb-11 mx-auto`}
  margin-top: 20px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 30px;
  }
`

export const FAQ = (props: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div {...props}>
      <SectionTitle>Frequently asked questions</SectionTitle>
      {FAQData.map((item, idx) => (
        <FAQItem key={idx} item={item} />
      ))}
    </div>
  )
}
